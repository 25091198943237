<template>
    <div>
        <Header :id="id"></Header>
        <div class="product">
          <div class="prod">
            <div class="prod1">{{ $t('products') }}</div>
            <div class="prod2">
             <template v-for="(item,index) in list">
              <div class="prod3"  :key="index" @mouseover="tag=true" @mouseout="tag=false">
                <router-link :to="{ name: 'ThreeProductdetail', params: { id: item.productId }}" target="_blank">
                  <div class="prodimg">
                      <img :src="item.thumb" alt="">
                  </div>
                  <div class="protxt">
                    {{item.productName}}
                  </div>
                  <div class="protxt1">{{ $t('MOQ') }}: {{item.minOrder}}</div>
                  <div class="protxt2">{{item.companyName}}</div>
                  <div class="out" v-show="tag">
                    <div class="outimg">
                      <img src="@/assets/images/xuanzhuan.jpg" alt="">
                    </div>
                  </div>
                </router-link>
              </div>
             </template>
            </div>
          </div>
          <!-- 分页 -->
          <div class="page">
              <a-pagination
                showQuickJumper
                :defaultCurrent="pagination.defaultCurrent"
                :defaultPageSize="pagination.defaultPageSize"
                :total="pagination.total"
                @change="onChange"
                @showSizeChange="onShowSizeChange"
              />
          </div>
        </div>
        <!-- 3d -->
        <div>
          <div id="container"></div>
        </div>
         <model-obj src="example/models/obj/LeePerrySmith.obj"></model-obj>
        <Footer></Footer>
    </div>
</template>
<script src="https://unpkg.com/vue-3d-model/dist/vue-3d-model.min.js"></script>
<script>

import Header from "./components/Header";
import Footer from "../components/Footer";
import * as Three from 'three';   // 引入three
import { ModelObj } from 'vue-3d-model'
export default {
  name: "Threed",
  components: {
    Footer,
    Header,
    ModelObj 
  },
   data() {
    return {
      list: [],
      id: this.$route.params.id,
      queryParam: {
        exhibitionId: this.$route.params.id,
        categoryId: '',
        childCategoryId: '',
        isThreed: 1
      },
      pagination: {
        showQuickJumper: true,
        showSizeChanger: true,
        defaultCurrent: 1, // 默认当前页数
        defaultPageSize: 8, // 默认当前页显示数据的大小
        total: 0 // 总数，必须先有
      },
      tag: false,
      token: localStorage.token,
    };
  },
  mounted () {
    if (this.token == '') {
      this.$layer.msg('请先登录');
      this.$router.go(-1)
    }
    this.getList()
  },
   methods: {
    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1);
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
    //分页
    onShowSizeChange (current, pageSize) {
      this.pagination.defaultCurrent = 1
      this.pagination.defaultPageSize = pageSize
      this.getList() // 显示列表的接口名称
    },
    onChange(current, size) {
      this.pagination.defaultCurrent = current
      this.pagination.defaultPageSize = size
      // 查数据
      this.getList()
    },
    // 获取展会商品列表
    getList () {
      const params = {
        page: this.pagination.defaultCurrent,
        pagesize: this.pagination.defaultPageSize
      }
       this.$common.fetchList('/exhibition/product/lists', Object.assign({}, params, this.queryParam)).then(data => {
        if (data.code === 200) {
          const result = data.data
          const pagination = { ...this.pagination }
          pagination.total = result.totalInfo.total_items
          this.list = result.lists
          this.pagination = pagination
        }
      }).catch(() => {
        // this.$notification.error({
        // message: '错误',
        // description: '请求信息失败，请重试'
        // })
      })
    }
  },
}
</script>

<style lang="less" scoped>
  @import '~@/assets/css/threed.less';
</style>
